<template>
  <div class="logoBg" style="height: 100vh">
    <div class="bindPhoneNumber">
      <div class="phoneNumberLeft">
        <img src="../../assets/img/denglu.png" class="logIcon" />
        <p class="logoDes">
          提供跨境业务AI能力及解决方案，综合运用NLP、CV、多模态、
          ML等技术，解决社媒引流、站内营销、风控安全等业务问题。
          让跨境生意更简单、更高效。
        </p>
        <img src="../../assets/img/logodes.png" class="logDes" />
      </div>
      <div class="login-container" id="login_container"></div>
    </div>
    <!-- <img src="../../assets/img/logo/1.png" class="logo_1">
        <img src="../../assets/img/logo/2.png" class="logo_2">
        <img src="../../assets/img/logo/3.png" class="logo_3"> -->
    <img src="../../assets/img/logo/1.png" class="logo_1" />
    <img src="../../assets/img/logo/3.png" class="logo_3" />
    <img src="../../assets/img/logo/2.png" class="logo_2" />
    <img src="../../assets/img/logo/4.png" class="logo_4" />
  </div>
</template>

<script>
import { weiXinLog } from "../../api/aidata";
// 公用组件
import "../../assets/css/public.css";

export default {
  data() {
    return {};
  },
  mounted() {
    let access_page = this.$route.query["url"] || "https://aishipgo.com/";
    weiXinLog({
      access_page: access_page,
    }).then(({ data }) => {
      if (data.state == "0x0000") {
        //获取 url 拼接地址
        var wData = data.data;
        if (wData) {
          new WxLogin({
            self_redirect: false,
            id: "login_container",
            appid: wData.appid,
            scope: wData.scope,
            redirect_uri: wData.redirect_uri,
            state: wData.state,
            style: "black",
            href: "",
          });
        }
      } else {
        this.$message.error(data.message);
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    // 获取前一页的路由
    next((vm) => {
      // 通过 `vm` 访问组件实例,将值传入fromPath
      vm.fromPath = from.path;
    });
  },
  methods: {},
};
</script>

<style scoped>
.logoBg {
  width: 100%;
  /* background: url("../../assets/img/logobg.png") no-repeat; */
  /* background-size: 100%  100%; */
  /* background: red; */
  background-color: #fffefa;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1200px;
}
.bindPhoneNumber {
  width: 1100px;
  /* height: 800px; */
  background: rgba(31, 29, 140, 0.1);
  border-radius: 10px 10px 10px 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
  padding-top: 100px;
  z-index: 9999;
}
.phoneNumberLeft {
  width: 510px;
  float: left;
}
.logIcon {
  width: 400px;
  display: block;
  margin: auto;
}
.logDes {
  width: 500px;
  display: block;
  margin: auto;
  margin-top: 70px;
}
.login-container {
  width: 540px;
  float: right;
  text-align: center;
  margin-top: 100px;
}
.logoDes {
  text-align: center;
  font-family: PingFang-SC-Medium, PingFang-SC;
  line-height: 28px;
  color: #000100;
  font-weight: 500;
  font-size: 18px;
  margin-top: 47px;
}
.logo_1 {
  position: absolute;
  left: 30%;
  bottom: 0;
  width: 20%;
}
.logo_3 {
  position: absolute;
  left: 10%;
  top: 0;
  width: 20%;
}
.logo_2 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 25%;
}
.logo_4 {
  position: absolute;
  right: 0;
  top: 0;
  width: 30%;
}
@media screen and (max-width: 1000px) {
  .logoBg {
    width: 100%;
    min-width: 100%;
  }
  .bindPhoneNumber {
    width: 100%;
  }
  .phoneNumberLeft {
    display: none;
  }
  .login-container {
    width: 100%;
    margin-top: 50px;
  }
}
</style>
